import { useEffect, useState } from 'react';

import { useQuerySearchParams } from './useQuerySearchParams';

export interface UseDrawerResizeReturn {
  onResizeStart: () => void;
  width: number;
}

export interface UseDrawerResizeProps {
  direction?: 'left' | 'right';
  initWidth: number;
  minWidth?: number;
  onResizeEnd?: (width: number) => void;
}

const WIDE_WIDTH = 600;

// TODO: test
export const useDrawerResize = ({
  direction = 'left',
  initWidth,
  minWidth = 0,
  onResizeEnd,
}: UseDrawerResizeProps): UseDrawerResizeReturn => {
  const [width, setWidth] = useState<number>(initWidth);
  const [resizing, setResizing] = useState<boolean>(false);

  const { getParams } = useQuerySearchParams();
  const { drawerWidth } = getParams(['drawerWidth']);

  useEffect(() => {
    if (drawerWidth === 'wide' && WIDE_WIDTH > width) {
      setWidth(WIDE_WIDTH);
    }
  }, [drawerWidth, width]);

  const handleResizeStart = () => {
    setResizing(true);
  };

  useEffect(() => {
    if (resizing) {
      let newWidth: number;

      const onMouseUp = () => {
        setResizing(false);
        if (onResizeEnd) {
          onResizeEnd(newWidth);
        }

        document.body.removeEventListener('mouseup', onMouseUp);
      };

      const resizingEvt = (event: MouseEvent) => {
        const w =
          direction === 'left' ? event.pageX : window.innerWidth - event.pageX;
        newWidth = w >= minWidth ? w : minWidth;

        setWidth(newWidth);
        document.body.addEventListener('mouseup', onMouseUp);
      };

      document.body.addEventListener('mousemove', resizingEvt);

      return () => {
        document.body.removeEventListener('mousemove', resizingEvt);
        document.body.removeEventListener('mouseup', onMouseUp);
      };
    }

    return undefined;
  }, [resizing, setWidth, onResizeEnd, direction, minWidth]);

  return {
    onResizeStart: handleResizeStart,
    width,
  };
};
