import React, { FC } from 'react';

import { Alert, AlertTitle, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ResearchResult } from './ResearchResult';
import { useMessageWaitReport } from './useMessageWaitReport';

interface Props {
  noteId: string;
}

export const MessageWaitReport: FC<Props> = ({ noteId }) => {
  const { isFailed, isLoaded, note } = useMessageWaitReport(noteId);
  const { t } = useTranslation('chat');

  if (isLoaded && note) {
    return (
      <>
        <Typography variant="body2">
          {t('chat:message.report.ready')}
        </Typography>
        <ResearchResult note={note} />
      </>
    );
  }

  if (isFailed) {
    return (
      <Alert severity="error">
        <AlertTitle>{t('chat:message.report.error')}</AlertTitle>
      </Alert>
    );
  }

  return (
    <>
      <Button disabled>{t('chat:message.report.button.loading')}</Button>
      <Alert severity="info">{t('chat:message.report.info.loading')}</Alert>
    </>
  );
};
