import React, { FC, useState } from 'react';

import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { NoteDetails } from 'api/notesApi/notesApi.types';
import { useQuerySearchParams } from 'common/hooks/useQuerySearchParams';

import { NoteInChat } from '../NoteInChat/NoteInChat';

interface Props {
  note: NoteDetails;
}

export const ResearchResult: FC<Props> = ({ note }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { deleteParams, setParams } = useQuerySearchParams();
  const { t } = useTranslation('chat');

  const handleOpenClick = () => {
    if (isOpened) {
      deleteParams(['drawerWidth']);
    } else {
      setParams({ drawerWidth: 'wide' });
    }
    setIsOpened((v) => !v);
  };

  return (
    <>
      <Box sx={{ mt: 1 }}>
        {isOpened && (
          <>
            <NoteInChat note={note} />
          </>
        )}
        <Box sx={{ textAlign: 'right' }}>
          <Button onClick={handleOpenClick}>
            {isOpened
              ? t('chat:message.report.button.ready.hide')
              : t('chat:message.report.button.ready.open')}
          </Button>
        </Box>
      </Box>
    </>
  );
};
