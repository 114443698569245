import { Optional } from 'common/utils/assert';
import { useParsedHostname } from 'common/utils/useParsedHostname';

import { useNotesList } from './useNotesList';

export const useSingleNote = (noteId: Optional<string>) => {
  const { tenant } = useParsedHostname();

  const note = useNotesList({
    noteIds: noteId ? [+noteId] : [],
    tenant,
  });

  return note;
};
