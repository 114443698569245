import { useEffect, useState } from 'react';

interface Props<T> {
  maxAttempts?: number;
  request: () => Promise<T>;
  timeout?: number;
  validation: (response: T) => boolean;
}

interface Result<T> {
  data: T | Error | undefined;
  isFailed: boolean;
  isLoaded: boolean;
  isLoading: boolean;
}

export const usePingRequest = <T>({
  maxAttempts = 100,
  request,
  timeout = 10000,
  validation,
}: Props<T>): Result<T> => {
  const [data, setData] = useState<T | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  useEffect(() => {
    let attempts = 0;
    let isCancelled = false;

    const ping = async () => {
      attempts++;
      try {
        const response = await request();

        if (isCancelled) {
          return;
        }

        setData(response);

        if (validation(response)) {
          setIsLoaded(true);
          setIsLoading(false);
          return;
        }
      } catch (error) {
        if (!isCancelled) {
          setData(error as Error);
        }
      }

      if (attempts >= maxAttempts) {
        if (isCancelled) {
          return;
        }
        setIsFailed(true);
        setIsLoading(false);
        return;
      }

      setTimeout(ping, timeout);
    };

    void ping();

    return () => {
      isCancelled = true;
    };
  }, [maxAttempts, request, timeout, validation]);

  return {
    data,
    isFailed,
    isLoaded,
    isLoading,
  };
};
