import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';

import { Nullable } from 'common/utils/assert';
import { Conversation } from 'containers/Chat/Chat.types';
import { ConversationList } from 'containers/Chat/Conversation/ConversationList';

import { NoteItemMarkdown } from './NoteItemMarkdown/NoteItemMarkdown';
import { ShortConversationList } from './ShortConversationList/ShortConversationList';

export interface NoteItemContentProps extends BoxProps {
  content: string;
  conversation: Nullable<Conversation>;
  editable?: boolean;
  highlight?: string;
  initials: string | undefined;
  isChatConversation: Nullable<boolean>;
  isShortNote?: boolean;
}

export const NoteItemContent = ({
  content,
  conversation,
  editable,
  highlight,
  initials,
  isChatConversation,
  isShortNote,
  ...props
}: NoteItemContentProps) => {
  const contentComponent = useMemo(() => {
    if (!conversation || !conversation.messages.length) {
      return (
        <NoteItemMarkdown content={highlight || content} editable={editable} />
      );
    }
    if (isShortNote) {
      return <ShortConversationList conversation={conversation} />;
    }
    return <ConversationList conversation={conversation} initials={initials} />;
  }, [content, conversation, editable, highlight, initials, isShortNote]);

  return (
    <Box
      data-testid="NoteItemContent"
      sx={{
        maxHeight: isShortNote ? (isChatConversation ? 40 : 100) : 500,
        overflowY: isShortNote
          ? isChatConversation
            ? 'initial'
            : 'scroll'
          : 'auto',
      }}
    >
      <Box {...props}>{contentComponent}</Box>
    </Box>
  );
};
