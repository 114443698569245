import { useCallback } from 'react';

import { UseQueryResult } from 'react-query';

import { PaginatedResults } from 'api/models/PaginatedResults';
import { NoteDetails } from 'api/notesApi/notesApi.types';
import { usePingRequest } from 'common/hooks/usePingRequest';
import { Nullable } from 'common/utils/assert';
import { useSingleNote } from 'containers/SavedNotes/hooks/useSingleNote';

export const useMessageWaitReport = (noteId: string) => {
  const note = useSingleNote(noteId);

  const request = useCallback(() => note.query.refetch(), [noteId]);

  const validation = useCallback(
    (data: UseQueryResult<PaginatedResults<NoteDetails>>): boolean =>
      !!data?.data?.items[0].content &&
      !data.data.items[0].content.startsWith('Report generation in progress') &&
      !data.data.items[0].content.startsWith('Report generation failed'),
    []
  );

  const result = usePingRequest({
    request,
    validation,
  });

  const noteDetails: Nullable<NoteDetails> =
    // @ts-ignore
    result.data?.data?.items[0] ?? null;

  return { ...result, note: noteDetails };
};
