import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  content: {
    '& blockquote': {
      fontSize: '0.8rem',
      margin: theme.spacing(0, 0, 0, 2),
      padding: 0,
    },
    '& blockquote p': {
      margin: theme.spacing(0, 0, 0.5, 0),
      padding: 0,
    },
    '& h2': {
      color: theme.palette.text.disabled,
      fontSize: '1.1rem',
      margin: theme.spacing(2, 0, 0, 1),
      textAlign: 'center',
    },
    '& h3': {
      color: theme.palette.text.disabled,
      fontSize: '0.6rem',
      margin: theme.spacing(0.5, 0, 0, 1),
      padding: 0,
      textTransform: 'uppercase',
    },
    '& hr': {
      border: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(0.1, 0),
      opacity: 0.3,
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    bgcolor: 'background.paper',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 1,
    // maxHeight: '100px',
    // overflowY: 'scroll',
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    'scrollbar-width': 'none',
    transition: theme.transitions.create('box-shadow'),
  },
  editable: {
    '&:hover': {
      bgcolor: 'common.white',
      boxShadow: 3,
    },
    cursor: 'pointer',
  },
}));
