import React, { FC, ReactNode } from 'react';

import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';

import { FunctionCallProperties } from 'api/chatApi/chatApi.types';
import TrackedButton from 'common/components/Buttons/TrackedButton/TrackedButton';
import { MarkdownText } from 'common/components/Markdown/MarkdownText';
import NoteAnnotationHighlightItem from 'common/components/Notes/NoteAnnotationHighlightItem';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useQuerySearchParams } from 'common/hooks/useQuerySearchParams';

import { Message } from '../Message';

import { DynamicContextButtonWithDialog } from './DynamicContext/DynamicContextButtonWithDialog';
import { MessageWaitReport } from './MessageWaitReport/MessageWaitReport';

export interface ConversationMessageProps {
  content: string;
  docsIds?: string[] | undefined;
  extra?: ReactNode;
  functionCallRequest?: FunctionCallProperties;
  image?: string | null;
  initials?: string;
  sender: ChatMessageSenderEnum;
}

export const ConversationMessage: FC<ConversationMessageProps> = ({
  content,
  docsIds,
  extra,
  functionCallRequest,
  image,
  initials,
  sender,
}) => {
  const { t } = useTranslation(['chat']);
  const { setParams } = useQuerySearchParams();

  const openSuggestedDocsModal = () => {
    setParams(
      {
        fc: functionCallRequest!.name,
      },
      {
        functionCallParams: JSON.stringify(functionCallRequest!.params),
      }
    );
  };

  const isGenerateReport = !!(
    functionCallRequest?.name === 'fetch_report' &&
    functionCallRequest?.params?.note_id
  );

  return (
    <Message
      button={
        functionCallRequest && !isGenerateReport ? (
          <TrackedButton
            eventName={TrackEventName.TagOnboardingSuggestedDocsClicked}
            onClick={openSuggestedDocsModal}
          >
            {t(`message.functionButton.label`)}
          </TrackedButton>
        ) : null
      }
      extra={extra}
      initials={initials}
      isContent={!!content}
      sender={sender}
      topExtra={
        docsIds ? <DynamicContextButtonWithDialog docsIds={docsIds} /> : null
      }
    >
      <MarkdownText>{content}</MarkdownText>

      {image && (
        <NoteAnnotationHighlightItem
          contentBody={image}
          contentType={'image'}
        />
      )}

      {isGenerateReport && (
        <Box sx={{ mt: 2 }}>
          <MessageWaitReport noteId={functionCallRequest!.params!.note_id} />
        </Box>
      )}
    </Message>
  );
};
